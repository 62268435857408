<template>
  <!-- 一番赏商品 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="retrieveDiv flex flex-ac">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">用户名称：</span>
          <el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">事件：</span>
          <el-input class="w-250" v-model="retrieveForm.event" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">时间：</span>
          <el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
          </el-date-picker>
        </div>
        <div label="">
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
        userName: '',
        event: '',
        startDate: '',
        endDate: '',
      },
      startEndDate: [], //时间区间
      tabLoading: false,
      columns: [
        { type: 'selection', align: 'center', width: "55", fixed: 'left' },
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '用户名', prop: 'userName', align: 'left', 'show-overflow-tooltip': true },
        { label: 'ip', prop: 'addrIp', align: 'left', 'show-overflow-tooltip': true },
        { label: '事件', prop: 'operation', align: 'left', 'show-overflow-tooltip': true },
        { label: '时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //时间变化
    handPickerChange (value) {
      if (value && value.length != 0) {
        this.retrieveForm.startDate = value[0]
        this.retrieveForm.endDate = value[1]
      } else {
        this.retrieveForm.startDate = ''
        this.retrieveForm.endDate = ''
      }
    },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/sysLog/findSysLogList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          userName: this.retrieveForm.userName,
          event: this.retrieveForm.event,
          startDate: this.retrieveForm.startDate,
          endDate: this.retrieveForm.endDate,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>